/* Global variables */

:root {
  --border-radius: 0.5rem;

  --gutter: var(--size-fluid-2);
  --padding-inline: var(--size-fluid-2);
  --padding-block: var(--size-fluid-4);

  --transition-base: 250ms ease;
  --transition-movement: 200ms linear;
  --transition-fade: 200ms ease;
  --transition-bounce: 500ms cubic-bezier(0.5, 0.05, 0.2, 1.5);
  --tracking: -0.05ch;
  --tracking-s: -0.075ch;

  /* --color-primary: var(--orange-6); */
  --color-dark: var(--stone-10);
  --color-light: var(--stone-0);

}
