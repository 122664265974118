/* **************** */
/* Colors from Open-Props */
/* https://open-props.style/#colors */
/* **************** */
:root {
    /* Gray */
    --gray-0: #f8f9fa;
    --gray-1: #f1f3f5;
    --gray-2: #e9ecef;
    --gray-3: #dee2e6;
    --gray-4: #ced4da;
    --gray-5: #adb5bd;
    --gray-6: #868e96;
    --gray-7: #495057;
    --gray-8: #343a40;
    --gray-9: #212529;
    --gray-10: #16191d;
    --gray-11: #0d0f12;
    --gray-12: #030507;

    /* Stone */
    --stone-0: #f8fafb;
    --stone-1: #f2f4f6;
    --stone-2: #ebedef;
    --stone-3: #e0e4e5;
    --stone-4: #d1d6d8;
    --stone-5: #b1b6b9;
    --stone-6: #979b9d;
    --stone-7: #7e8282;
    --stone-8: #666968;
    --stone-9: #50514f;
    --stone-10: #3a3a37;
    --stone-11: #252521;
    --stone-12: #121210;

    /* Red */    
    --red-0: #fff5f5;
    --red-1: #ffe3e3;
    --red-2: #ffc9c9;
    --red-3: #ffa8a8;
    --red-4: #ff8787;
    --red-5: #ff6b6b;
    --red-6: #fa5252;
    --red-7: #f03e3e;
    --red-8: #e03131;
    --red-9: #c92a2a;
    --red-10: #b02525;
    --red-11: #962020;
    --red-12: #7d1a1a;
    
    /* Pink */
    --pink-0: #fff0f6;
    --pink-1: #ffdeeb;
    --pink-2: #fcc2d7;
    --pink-3: #faa2c1;
    --pink-4: #f783ac;
    --pink-5: #f06595;
    --pink-6: #e64980;
    --pink-7: #d6336c;
    --pink-8: #c2255c;
    --pink-9: #a61e4d;
    --pink-10: #8c1941;
    --pink-11: #731536;
    --pink-12: #59102a;
    
    /* Purple */
    --purple-0: #f8f0fc;
    --purple-1: #f3d9fa;
    --purple-2: #eebefa;
    --purple-3: #e599f7;
    --purple-4: #da77f2;
    --purple-5: #cc5de8;
    --purple-6: #be4bdb;
    --purple-7: #ae3ec9;
    --purple-8: #9c36b5;
    --purple-9: #862e9c;
    --purple-10: #702682;
    --purple-11: #5a1e69;
    --purple-12: #44174f;
    
    /* Violet */
    --violet-0: #f3f0ff;
    --violet-1: #e5dbff;
    --violet-2: #d0bfff;
    --violet-3: #b197fc;
    --violet-4: #9775fa;
    --violet-5: #845ef7;
    --violet-6: #7950f2;
    --violet-7: #7048e8;
    --violet-8: #6741d9;
    --violet-9: #5f3dc4;
    --violet-10: #5235ab;
    --violet-11: #462d91;
    --violet-12: #3a2578;

    /* Indigo */
    --indigo-0: #edf2ff;
    --indigo-1: #dbe4ff;
    --indigo-2: #bac8ff;
    --indigo-3: #91a7ff;
    --indigo-4: #748ffc;
    --indigo-5: #5c7cfa;
    --indigo-6: #4c6ef5;
    --indigo-7: #4263eb;
    --indigo-8: #3b5bdb;
    --indigo-9: #364fc7;
    --indigo-10: #2f44ad;
    --indigo-11: #283a94;
    --indigo-12: #21307a;

    /* Blue */
    --blue-0: #e7f5ff;
    --blue-1: #d0ebff;
    --blue-2: #a5d8ff;
    --blue-3: #74c0fc;
    --blue-4: #4dabf7;
    --blue-5: #339af0;
    --blue-6: #228be6;
    --blue-7: #1c7ed6;
    --blue-8: #1971c2;
    --blue-9: #1864ab;
    --blue-10: #145591;
    --blue-11: #114678;
    --blue-12: #0d375e;

    /* Cyan */
    --cyan-0: #e3fafc;
    --cyan-1: #c5f6fa;
    --cyan-2: #99e9f2;
    --cyan-3: #66d9e8;
    --cyan-4: #3bc9db;
    --cyan-5: #22b8cf;
    --cyan-6: #15aabf;
    --cyan-7: #1098ad;
    --cyan-8: #0c8599;
    --cyan-9: #0b7285;
    --cyan-10: #095c6b;
    --cyan-11: #074652;
    --cyan-12: #053038;

    /* Teal */
    --teal-0: #e6fcf5;
    --teal-1: #c3fae8;
    --teal-2: #96f2d7;
    --teal-3: #63e6be;
    --teal-4: #38d9a9;
    --teal-5: #20c997;
    --teal-6: #12b886;
    --teal-7: #0ca678;
    --teal-8: #099268;
    --teal-9: #087f5b;
    --teal-10: #066649;
    --teal-11: #054d37;
    --teal-12: #033325;

    /* Green */
    --green-0: #ebfbee;
    --green-1: #d3f9d8;
    --green-2: #b2f2bb;
    --green-3: #8ce99a;
    --green-4: #69db7c;
    --green-5: #51cf66;
    --green-6: #40c057;
    --green-7: #37b24d;
    --green-8: #2f9e44;
    --green-9: #2b8a3e;
    --green-10: #237032;
    --green-11: #1b5727;
    --green-12: #133d1b;

    /* Lime */
    --lime-0: #f4fce3;
    --lime-1: #e9fac8;
    --lime-2: #d8f5a2;
    --lime-3: #c0eb75;
    --lime-4: #a9e34b;
    --lime-5: #94d82d;
    --lime-6: #82c91e;
    --lime-7: #74b816;
    --lime-8: #66a80f;
    --lime-9: #5c940d;
    --lime-10: #4c7a0b;
    --lime-11: #3c6109;
    --lime-12: #2c4706;

    /* Yellow */
    --yellow-0: #fff9db;
    --yellow-1: #fff3bf;
    --yellow-2: #ffec99;
    --yellow-3: #ffe066;
    --yellow-4: #ffd43b;
    --yellow-5: #fcc419;
    --yellow-6: #fab005;
    --yellow-7: #f59f00;
    --yellow-8: #f08c00;
    --yellow-9: #e67700;
    --yellow-10: #b35c00;
    --yellow-11: #804200;
    --yellow-12: #663500;

    /* Orange */
    --orange-0: #fff4e6;
    --orange-1: #ffe8cc;
    --orange-2: #ffd8a8;
    --orange-3: #ffc078;
    --orange-4: #ffa94d;
    --orange-5: #ff922b;
    --orange-6: #fd7e14;
    --orange-7: #f76707;
    --orange-8: #e8590c;
    --orange-9: #d9480f;
    --orange-10: #bf400d;
    --orange-11: #99330b;
    --orange-12: #802b09;

    /* Chocolate */
    --choco-0: #fff8dc;
    --choco-1: #fce1bc;
    --choco-2: #f7ca9e;
    --choco-3: #f1b280;
    --choco-4: #e99b62;
    --choco-5: #df8545;
    --choco-6: #d46e25;
    --choco-7: #bd5f1b;
    --choco-8: #a45117;
    --choco-9: #8a4513;
    --choco-10: #703a13;
    --choco-11: #572f12;
    --choco-12: #3d210d;

    /* Brown */
    --brown-0: #faf4eb;
    --brown-1: #ede0d1;
    --brown-2: #e0cab7;
    --brown-3: #d3b79e;
    --brown-4: #c5a285;
    --brown-5: #b78f6d;
    --brown-6: #a87c56;
    --brown-7: #956b47;
    --brown-8: #825b3a;
    --brown-9: #6f4b2d;
    --brown-10: #5e3a21;
    --brown-11: #4e2b15;
    --brown-12: #422412;
    
    /* Sand */
    --sand-0: #f8fafb;
    --sand-1: #e6e4dc;
    --sand-2: #d5cfbd;
    --sand-3: #c2b9a0;
    --sand-4: #aea58c;
    --sand-5: #9a9178;
    --sand-6: #867c65;
    --sand-7: #736a53;
    --sand-8: #5f5746;
    --sand-9: #4b4639;
    --sand-10: #38352d;
    --sand-11: #252521;
    --sand-12: #121210;
    
    /* Camo */
    --camo-0: #f9fbe7;
    --camo-1: #e8ed9c;
    --camo-2: #d2df4e;
    --camo-3: #c2ce34;
    --camo-4: #b5bb2e;
    --camo-5: #a7a827;
    --camo-6: #999621;
    --camo-7: #8c851c;
    --camo-8: #7e7416;
    --camo-9: #6d6414;
    --camo-10: #5d5411;
    --camo-11: #4d460e;
    --camo-12: #36300a;

    /* Jungle */
    --jungle-0: #ecfeb0;
    --jungle-1: #def39a;
    --jungle-2: #d0e884;
    --jungle-3: #c2dd6e;
    --jungle-4: #b5d15b;
    --jungle-5: #a8c648;
    --jungle-6: #9bbb36;
    --jungle-7: #8fb024;
    --jungle-8: #84a513;
    --jungle-9: #7a9908;
    --jungle-10: #658006;
    --jungle-11: #516605;
    --jungle-12: #3d4d04;

}